<template>
  <div class="leads">
    <b-card>
      <div class="d-flex justify-content-center align-items-center" v-if="loading" style="height:50vh;color:#4c62e3">
        <b-spinner></b-spinner>&nbsp;&nbsp;Loading...
      </div>
      <div v-else v-html="htmlData"></div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      loading: false,
      htmlData: null
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      this.loading = true
      const response = await axios.get('https://www.soocel.com/wp-json/wp/v2/pages/3622')
      this.htmlData = response.data.content.rendered
      this.loading = false
    }
  }
}
</script>

<style>
  .leads table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  .leads table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }

  .leads table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
  }

  .leads table th,
  .leads table td {
    padding: .625em;
    /* text-align: center; */
  }

  .leads table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }

  @media screen and (max-width: 600px) {
    .leads table {
      border: 0;
    }

    .leads table caption {
      font-size: 1.3em;
    }

    .leads table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    .leads table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }

    .leads table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }
    .leads table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    .leads table td:last-child {
      border-bottom: 0;
    }
  }
</style>
